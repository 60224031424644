@charset "UTF-8";
@font-face {
    font-family: 'HaikuP';
    src: url('./assets/fonts/HaikuP.woff') format('woff2'), url('./assets/fonts/HaikuP.woff') format('woff');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HaikuS';
    src: url('./assets/fonts/HaikuS.woff2') format('woff2'), url('./assets/fonts/HaikuS.woff') format('woff');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

#root {
    height: 100%;
}

body {
    font-family: HaikuP, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
}
